<template>
  <div id="buy-from-jci-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>For all transactions over Rs. 500, invoice number & invoice image is mandatory.</li>
            <li>For all transactions over Rs. 10000, payment screenshot is mandatory.</li>
            <li>For all products over Rs. 50000, eway bill screenshot is mandatory.</li>
            <li>Each image should be less than 2MB.</li>
          </ol>
        </v-container>
        <v-row wrap>
          <v-col align="center" cols="12">
            <v-btn
              @click.prevent="backPage"
              elevation="30"
              color="primary"
              class="font-size-h6 fsize-3 mr-3 mt-5 my-3 white--text"
            >Back</v-btn>
            <v-btn
              @click.prevent="resetForm"
              elevation="30"
              color="warning"
              class="font-size-h6 fsize-3 mr-3 mt-5 my-3 white--text"
            >Reset</v-btn>
          </v-col>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p>
                <span class="text-danger">*</span> Indicates required field
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-dialog v-model="addProfessionDetailsPrompt" persistent width="auto">
            <add-new-profession
              pageDescription="Add Business Details"
              :addRecordPrompt="addProfessionDetailsPrompt"
              @hideAddRecordPrompt="hideaddProfessionDetailsPrompt"
              v-if="addProfessionDetailsPrompt"
            ></add-new-profession>
          </v-dialog>

          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">Buyer Details</v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Buyer Membership Id
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MembershipId"
                      :rules="MembershipIdRules"
                      hint="Enter the Membership Id"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col align="center" cols="12" sm="6" lg="4" md="4">
                    <v-btn
                      :disabled="!valid2"
                      @click.prevent="searchForm"
                      :loading="SearchFlag"
                      elevation="30"
                      color="primary"
                      class="font-size-h6 fsize-3 mr-3 mt-5 my-3 white--text"
                    >Search</v-btn>
                  </v-col>
                </v-row>
                <v-row wrap v-if="BuyerFlag">
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <img
                      v-if="Buyer.MemberImage != ''"
                      width="100"
                      height="100"
                      :src="Buyer.MemberImage"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Buyer Name</h6>
                    </label>
                    <h3>{{Buyer.Fullname}}</h3>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Buyer Phone No
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MobileNo"
                      :rules="MobileNoRules"
                      hint="Enter the Phone No"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Buyer Email
                      </h6>
                    </label>
                    <v-text-field
                      v-model="EmailId"
                      :rules="EmailRules"
                      hint="Enter the Email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-card-title
                class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
              >Seller's Business Details</v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Select Business
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="ProfessionCodeOptionsLoading"
                      :items="ProfessionCodeOptions"
                      :rules="ProfessionCodeRules"
                      v-model="ProfessionCode"
                      dense
                      clearable
                      required
                      outlined
                    >
                      <template v-slot:append-outer>
                        <v-btn icon color="info" @click.prevent="addProfessionDetailsPrompt = true">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col align="center" cols="12" sm="3" lg="2" md="2" v-if="ProfessionFlag">
                    <h6>Business Logo</h6>
                    <img
                      v-if="Profession.CompanyLogoPath != ''"
                      width="100"
                      height="100"
                      :src="Profession.CompanyLogoPath"
                    />
                  </v-col>
                  <v-col align="center" cols="12" sm="3" lg="3" md="3" v-if="ProfessionFlag">
                    <h6>Business Address</h6>
                    <p>{{Profession.text}}</p>
                    <p>{{Profession.Address}}</p>
                    <p>{{Profession.MobileNo}}</p>
                    <p>{{Profession.EmailId}}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Sales Type
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="SalesTypeOptionsLoading"
                      :items="SalesTypeOptions"
                      :rules="SalesTypeRules"
                      v-model="SalesType"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <label>
                      <h6>Product/Sales Description</h6>
                    </label>
                    <v-textarea
                      v-model="ProductDescription"
                      :rules="ProductDescriptionRules"
                      value
                      :maxlength="300"
                      :counter="300"
                      outlined
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">Transaction Details</v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="4" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Date
                      </h6>
                    </label>
                    <v-dialog
                      v-model="DateModal1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="TransactionDate"
                          :rules="TransactionDateRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="TransactionDate"
                        @input="DateModal1 = false"
                        :max="TodayDate"
                      ></v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Type
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="TransactionTypeOptionsLoading"
                      :items="TransactionTypeOptions"
                      :rules="TransactionTypeRules"
                      v-model="TransactionType"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Value
                      </h6>
                    </label>
                    <v-text-field
                      v-model="TransactionValue"
                      :rules="TransactionValueRules"
                      step=".01"
                      type="number"
                      min="0"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Completed On Date
                      </h6>
                    </label>
                    <v-dialog
                      v-model="DateModal2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="TransactionCompletedDate"
                          :rules="TransactionCompletedDateRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="TransactionCompletedDate"
                        @input="DateModal2 = false"
                        :max="TodayDate"
                      ></v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3" v-if="TransactionFlag1">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Invoice No
                      </h6>
                    </label>
                    <v-text-field v-model="InvoiceNo" :rules="InvoiceNoRules" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3" v-if="TransactionFlag1">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Please upload a copy of your GST Invoice
                      </h6>
                    </label>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload pdf"
                      v-model="GstInvoice"
                      :rules="GstInvoiceRules"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="GstInvoice = ''">mdi-delete</v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3" v-if="TransactionFlag3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Please upload a copy of your ewaybill
                      </h6>
                    </label>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload pdf"
                      v-model="EwayBill"
                      :rules="EwayBillRules"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="EwayBill = ''">mdi-delete</v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" md="3" v-if="TransactionFlag2">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Receipt/ Screenshot
                      </h6>
                    </label>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload pdf"
                      v-model="TransactionReceipt"
                      :rules="TransactionReceiptRules"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="TransactionReceipt = ''">mdi-delete</v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <br />
                <v-btn
                  :disabled="!valid1"
                  type="submit"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#EDBE38"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark>mdi-briefcase-check</v-icon>&nbsp; Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AddNewProfession from "@/view/pages/profile/my-profile/AddNewProfession";

export default {
  mixins: [common],
  components: {
    AddNewProfession
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      NotesFlag: true,

      progessStart: 0,

      MembershipId: "",
      MembershipIdRules: [v => !!v || "Membership Id is required"],

      Buyer: {},
      BuyerFlag: false,

      ProfessionFlag: false,
      Profession: {},

      addProfessionDetailsPrompt: false,

      TransactionFlag1: false,
      TransactionFlag2: false,
      TransactionFlag3: false,

      TodayDate: new Date().toISOString().slice(0, 10),
      DateModal1: false,
      DateModal2: false,
      TransactionDate: new Date().toISOString().slice(0, 10),
      TransactionCompletedDate: new Date().toISOString().slice(0, 10),
      TransactionDateRules: [v => !!v || "Transaction Date is required"],
      TransactionCompletedDateRules: [
        v => !!v || "Transaction Completed Date is required"
      ],

      EmailId: "",
      MobileNo: "",
      TransactionValue: "0.00",
      InvoiceNo: "",
      ProductDescription: "",

      EmailRules: [v => !!v || "Email is required"],
      MobileNoRules: [v => !!v || "Mobile No is required"],
      TransactionValueRules: [
        v => !v || v > 0.00 || "Transaction Value should be greater than 1",
        v => !!v || "Transaction Value is required"
      ],
      InvoiceNoRules: [v => !!v || "Invoice No is required"],
      ProductDescriptionRules: [
        v => !!v || "Product/sales Description is required"
      ],

      GstInvoice: "",
      GstInvoiceRules: [
        v =>
          !v ||
          v.size < 2000000 ||
          "GST Invoice size should be less than 2 MB!",
        v => !!v || "GST Invoice is required"
      ],

      EwayBill: "",
      EwayBillRules: [
        v =>
          !v || v.size < 2000000 || "EwayBill size should be less than 2 MB!",
        v => !!v || "EwayBill is required"
      ],

      TransactionReceipt: "",
      TransactionReceiptRules: [
        v =>
          !v ||
          v.size < 2000000 ||
          "Transaction Receipt size should be less than 2 MB!",
        v => !!v || "Transaction Receipt is required"
      ],

      SalesTypeRules: [v => !!v || "Sales Type is required"],
      SalesType: "",
      SalesTypeOptions: [
        { value: "", text: "--Select Sales Type--" },
        { value: 1, text: "Products" },
        { value: 2, text: "Services" }
      ],
      SalesTypeOptionsLoading: false,

      TransactionTypeRules: [v => !!v || "Transaction Type is required"],
      TransactionType: "",
      TransactionTypeOptions: [
        { value: "", text: "--Select Transaction Type--" },
        { value: 1, text: "Cash" },
        { value: 2, text: "Online Transfer/ RTGS/ NEFT" },
        { value: 3, text: "Cheque" },
        { value: 4, text: "Paytm/ Google Pay/ Online Wallet Transfer" },
        { value: 5, text: "UPI Transfer" }
      ],
      TransactionTypeOptionsLoading: false,

      ProfessionCodeRules: [v => !!v || "Profession is required"],
      ProfessionCode: "",
      ProfessionCodeOptions: [],
      ProfessionCodeOptionsLoading: false,

      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.LoadingFlag = false;
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var MemberId = this.$session.get("MemberId");
        this.MemberId = MemberId;
        console.log({ MemberId });

        var ZoneId = this.$session.get("ZoneId");
        this.ZoneCode = ZoneId;
        console.log({ ZoneId });

        var LomId = this.$session.get("LomId");
        this.LomCode = LomId;
        console.log({ LomId });

        this.getProfessionCodeOptions();
      }
    },
    ProfessionCodeOptions: function() {
      console.log("watch ProfessionCodeOptions");
      this.ProfessionCodeOptionsLoading = false;
    },
    TransactionValue: function() {
      console.log("watch TransactionValue");
      var TransactionValue = this.TransactionValue;
      var SalesType = this.SalesType;
      console.log(
        "TransactionValue=" + TransactionValue + ", SalesType=" + SalesType
      );

      this.TransactionFlag1 = TransactionValue > 500 ? true : false;
      this.TransactionFlag2 = TransactionValue > 10000 ? true : false;
      this.TransactionFlag3 =
        TransactionValue >= 50000 && SalesType != 2 ? true : false;

      console.log(
        "TransactionFlag1=" +
          this.TransactionFlag1 +
          ", TransactionFlag2=" +
          this.TransactionFlag2 +
          ", TransactionFlag3=" +
          this.TransactionFlag3
      );
    },
    SalesType: function() {
      console.log("watch TransactionValue");
      var TransactionValue = this.TransactionValue;
      var SalesType = this.SalesType;
      console.log(
        "TransactionValue=" + TransactionValue + ", SalesType=" + SalesType
      );

      this.TransactionFlag3 =
        TransactionValue >= 50000 && SalesType != 2 ? true : false;

      console.log(", TransactionFlag3=" + this.TransactionFlag3);
    },
    ProfessionCode: function() {
      console.log("watch ProfessionCode");
      var temp_code = this.ProfessionCode;
      console.log("temp_code=" + temp_code);
      var idx = this.ProfessionCodeOptions.map(e => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var Profession = this.ProfessionCodeOptions[idx];
        console.log("Profession=" + JSON.stringify(Profession));
        this.Profession = Profession;
        this.ProfessionFlag = Profession.text != "" ? true : false;
      }
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "buy_from_jci",
        Action: "create"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    resetForm() {
      this.$refs.form1.reset();
      this.Buyer = {};
      this.BuyerFlag = false;
      this.Profession = {};
      this.ProfessionFlag = false;
    },
    backPage() {
      this.$router.go(-1);
    },
    getProfessionCodeOptions() {
      console.log("ProfessionCode called");
      var MemberId = this.MemberId;
      this.ProfessionCodeOptions = [];
      console.log("MemberId=" + MemberId);
      if (MemberId != "") {
        this.ProfessionCodeOptionsLoading = true;
        var selectbox1_source = "ProfessionCode";
        var selectbox1_destination = "ProfessionCodeOptions";
        var selectbox1_url = "api/members-profession/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Member: MemberId
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (MemberId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    hideaddProfessionDetailsPrompt() {
      console.log("hideaddProfessionDetailsPrompt called");
      this.addProfessionDetailsPrompt = false;
      this.getProfessionCodeOptions();
    },
    searchForm() {
      console.log("searchForm is called");

      var MembershipId = this.MembershipId;
      console.log("MembershipId=" + MembershipId);

      if (MembershipId != "") {
        this.SearchFlag = true;
        this.BuyerFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: MembershipId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.Buyer = records;
              thisIns.BuyerFlag = true;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "";
        if (MembershipId == "") {
          message += "Membership Id should't be empty. ";
        }
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1 && this.BuyerFlag) {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/buy-from-jci/create";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var InvoiceNo = this.TransactionFlag1 ? this.InvoiceNo : "";
        var TransactionReceipt = this.TransactionFlag2
          ? this.TransactionReceipt
          : {};
        var EwayBill = this.TransactionFlag3 ? this.EwayBill : {};
        var GstInvoice = this.TransactionFlag1 ? this.GstInvoice : {};

        let upload = new FormData();

        upload.append("UserInterface", 1);
        upload.append("Year", this.JciYearCode);
        upload.append("SellerMember", this.MemberId);
        upload.append("BuyerMember", this.Buyer.MemberId);
        upload.append("EmailId", this.EmailId);
        upload.append("MobileNo", this.MobileNo);
        upload.append("ProfessionCode", this.ProfessionCode);
        upload.append("SalesType", this.SalesType);
        upload.append("ProductDescription", this.ProductDescription);
        upload.append("TransactionDate", this.TransactionDate);
        upload.append(
          "TransactionCompletedDate",
          this.TransactionCompletedDate
        );
        upload.append("TransactionType", this.TransactionType);
        upload.append("TransactionValue", this.TransactionValue);
        upload.append("InvoiceNo", InvoiceNo);
        upload.append("GstInvoice", GstInvoice);
        upload.append("TransactionReceipt", TransactionReceipt);
        upload.append("EwayBill", EwayBill);
        console.log("upload=" + JSON.stringify(upload));

        this.progessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressLoadingFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
              thisIns.$router.push("/buy-from-jci/list");
            } else {
              thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
            thisIns.toast("error", output);
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (!this.BuyerFlag) {
          message += "Buyer Membership Id is invalid. ";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#buy-from-jci-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>